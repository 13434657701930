/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #efefef;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #ced2d5;
}

.body-container {
    /* padding: 0% 7% 0% 7%; */
    padding: 0%;
    overflow-y: scroll;
}

.body-container .header-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
}

.body-container .header-wrapper .logo-wrapper {
    display: flex;
    align-items: center;
    height: 80px;
}

.body-container .header-wrapper .logo-wrapper a {
    height: 80px;
}

.body-container .header-wrapper .logo-wrapper a img {
    height: 80px;
    /* width: 140px; */
}

.body-container .header-wrapper .divider {
    height: 90%;
    padding-left: 0.625rem;
}

.body-container .header-wrapper .title {
    font-size: 1.25rem;
    font-weight: 700;
    padding-left: 0.625rem;
}

.content-container {
    /* height: calc(100vh - 85px); */
}

.body-container .content-container {
    margin-top: 0%;
    border-radius: 2px;
    height: calc(100vh - 80px);
    display: flex;
    width: 100%;
    overflow: hidden;
    position: relative;
}

@media (max-width: 768px) {
    .content-wrapper {
        display: none;
    }
}

.body-container .content-container .content-wrapper .upper-section {
    padding: 0 6%;
}

.content-wrapper .upper-section .title-wrapper {
    padding-top: 6%;
    text-align: center;
}

.content-wrapper .upper-section .title-wrapper .title {
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.84px;
}

.content-wrapper .upper-section .dropdowns-wrapper {
    padding-top: 100px;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
}

.upper-section .dropdowns-wrapper .dropdown-section {
    display: flex;
    flex-direction: column;
    width: 30%;
    row-gap: 1.25rem;
    flex: 1 1 auto;
    min-width: 230px;
}

.upper-section .dropdowns-wrapper .dropdown-section:nth-child(2) {
    width: 35%;
}

.upper-section .dropdowns-wrapper .dropdown-section .dd-title {
    padding-left: 1.25rem;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.36px;
}

.upper-section .dropdowns-wrapper .dropdown-section .MuiFormControl-root {
    width: 100%;
}

.dropdown-section .MuiFormControl-root .MuiSelect-select {
    padding: 1.25rem;
    padding-right: 1.875rem;
    box-sizing: border-box;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
}

.dropdown-section .chat-select .MuiSelect-select {
    padding: 1rem;
    padding-right: 1.875rem;
}

.dropdown-section .MuiFormControl-root fieldset {
    border-radius: 2px;
    border: 1px solid #25323E;
}

.content-wrapper .upper-section .questions-wrapper {
    padding-top: 10.5%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}

.upper-section .questions-wrapper .q-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.625rem;
}

.upper-section .questions-wrapper .q-title-wrapper .q-title {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.48px;
}

.upper-section .questions-wrapper .questions-section {
    padding-top: 1.875rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 1rem;
}

.upper-section .questions-wrapper .questions-section .question {
    padding: 1.5625rem;
    text-align: center;
    border-radius: 10px;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
    display: inline-flex;
    align-items: center;
    width: 30%;
    min-width: 180px;
    flex: 1 1 auto;
}

.content-wrapper .search-bar-wrapper {
    padding-top: 10%;
}

.content-wrapper .search-bar-wrapper .search-bar {
    border-radius: 50px;
    border: 1px solid #9B9B9B;
    background: #FFF;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.36px;
}

.content-wrapper .search-bar-wrapper .search-bar .MuiOutlinedInput-input {
    padding-left: 1.25rem;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.36px;
    opacity: 0.6;
}

.content-wrapper .search-bar-wrapper .search-bar fieldset {
    border: none;
}

.content-wrapper .footer-wrapper {
    padding-top: 0.9375rem;
    padding-bottom: 3%;
}

.content-wrapper .footer-wrapper span {
    padding-left: 1.25rem;
    display: inline-block;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.32px;
}

.message__markdown.text-left>p:first-child {
    margin-top: 0px;
}

.message__markdown.text-left>p:last-child {
    margin-bottom: 0px;
}

.Mui-selected:hover {
    color: white !important;
    background-color: #4c3398 !important;
}

.user-avatar {
    background: #4c3398;
    border-radius: 4px;
}

.user-avatar svg {
    fill: #FFF;
    background: #4c3398;
}